<template>
    <div class="container">
        <van-nav-bar :title="$t('system.coinRanking')" left-arrow @click-left="onClickLeft" safe-area-inset-top fixed
            z-index="9999" />
        <div class="g_safe_top">
            <img class="banner" src="../../assets/imgs/new_color/banner1.png" alt="">
            <div class="ranking">
                <div class="listHead">
                    <div class="rank">{{$t('system.ranking')}}</div>
                    <div class="currency">{{$t('myPledge.currency')}}</div>
                    <div class="dailyProfitTakingFrequency">{{$t('system.dailyProfitTakingFrequency')}}</div>
                    <div class="volatilityActivity">{{$t('system.volatilityActivity')}}</div>
                </div>
                <div class="item" v-for="(item,key,index) in list" :key="index">
                    <div class="rank" v-if="index!=0&&index!=1&&index!=2">
                        {{index+1}}
                    </div>
                    <div class="rank" v-else>
                        <img :src="require('../../assets/imgs/new_color/rink'+(index+1)+'.png')" alt="">
                    </div>
                    <div class="currency">{{item.currency}}</div>
                    <div class="dailyProfitTakingFrequency">{{fixed(item.avg_daily_take_profit_frequency)}}</div>
                    <div class="volatilityActivity">{{fixed(item.range_daily*100)}}%</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                list: [],
            };
        },
        created() {
            this.getList();
        },
        methods: {
            fixed(data) {
                return Number(data).toFixed(2)
            },
            onClickLeft() {
                this.$router.back(-1);
            },
            routerTo(path) {
                this.$router.push(path)
            },

            //  获取列表
            getList() {
                let toast = this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    // message: '加载中...',
                    message: this.$t('system.Loading')
                });
                this.$post2('Trade/api/index/tokenranklist')
                    .then(res => {
                        // console.log(res)
                        this.list = res

                        toast.clear()
                    })
                    .catch(err => {
                        toast.clear()
                        this.$toast(err);
                    });
            },

        }
    };
</script>

<style lang="less" scoped>
    .container {
        .banner {
            width: 100%;
        }

        .ranking {
            margin: -0.48rem 0.17rem 0;
            padding: 0.16rem 0.17rem;
            background: #FFFFFF;
            border-radius: 16px;
            position: relative;
            z-index: 1;

            .listHead,
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                >div {
                    text-align: center;
                    padding: 0 0.05rem
                }
            }

            .listHead {
                font-weight: 500;
                font-size: 12px;
                color: #B9BABB;
                padding-bottom: 0.16rem;
            }

            .item {
                padding: 0.16rem 0;
                font-size: 14px;

                .currency {
                    color: #4C3000;
                }
            }

            .rank {
                flex: 0.2;

                img {
                    width: 0.25rem;
                    height: 0.25rem;
                }
            }

            .currency {
                flex: 0.2;
            }

            .dailyProfitTakingFrequency {
                flex: 0.3;
            }

            .volatilityActivity {
                flex: 0.3;
            }
        }
    }
</style>